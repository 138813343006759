<template>
  <section>
    <div class="container d-flex flex-column">
      <div class="row align-items-center justify-content-center min-vh-100">
        <div class="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0">
          <div>
            <div class="mb-4 text-center"><h6 class="h3 mb-1">Log in</h6>
              <p class="text-muted mb-0">Please login to account to continue</p></div>
            <span class="clearfix"></span>

            <AlertCollection ref="alerts"></AlertCollection>
            <form method="post" name="login-form">
              <div class="form-group"><label class="form-control-label">Login</label>
                <div class="input-group input-group-merge">
                  <input type="text"
                         v-model="username"
                         class="form-control form-control-prepend"
                         id="input-username"
                         name="username" required
                         placeholder="Login"
                  >
                  <div class="input-group-prepend"><span class="input-group-text"><svg
                    xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-user"><path
                    d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle
                    cx="12" cy="7" r="4"></circle></svg></span></div>
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="d-flex align-items-center justify-content-between">
                  <div><label class="form-control-label">Password</label></div>

                </div>
                <div class="input-group input-group-merge">
                  <input type="password"
                         v-model="password"
                         class="form-control form-control-prepend"
                         id="input-password"
                         name="password"
                         placeholder="Password">
                  <div class="input-group-prepend"><span class="input-group-text"><svg
                    xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-key"><path
                    d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg></span>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <button @click.prevent="doLogin" type="submit" class="btn btn-block btn-primary">Enter</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import AlertCollection from "@/components/Alert/AlertCollection";

export default {
  name: 'Login',
  components: {
    AlertCollection
  },
  methods: {
    doLogin: function () {
      this.$refs.alerts.clearAlerts();
      let username = this.username;
      let password = this.password;

      this.$store.dispatch('login', {
        username,
        password,
      }).catch((error) => {
        if (error.response && error.response.status === 401) {
          this.$refs.alerts.addAlert({message: error.response.data.message, type: 'danger'});
        }
        throw error;
      }).then(() => {
        this.$router.push('/');
      })
    },
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
    ]),
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push('/');
    }
  },
  watch: {},
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push('/');
    }
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
};
</script>
