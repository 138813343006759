import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueAxios from 'vue-axios'
import axios from 'axios'
import "./assets/css/bootstrap.css";
import 'v-calendar/dist/style.css';
import "./assets/css/fix.css";
import "@fortawesome/fontawesome-free/css/all.min.css"
import "vue-multiselect/dist/vue-multiselect.css"
import "./assets/css/vue-multiselect.css";
import Notifications from '@kyvg/vue3-notification'
import { vMaska } from "maska"

axios.defaults.baseURL = window.API_URL;
axios.defaults.headers.common = {
  'Content-Type': 'application/json;charset=UTF-8',
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

createApp(App).directive("maska", vMaska).use(VueAxios, axios).use(BootstrapVue3).use(Notifications).use(store).use(router).mount('#app')
