<template>
  <header class="" id="header-main">
    <nav class="navbar navbar-main navbar-expand-lg shadow navbar-dark bg-dark p-0" id="navbar-main">
      <div class="w-100">
        <button v-on:click="toggleMenu()" class="navbar-toggler order-lg-2 p-2 w-100" type="button" data-toggle="collapse"
                data-target="#navbar-main-collapse" aria-controls="navbar-main-collapse" aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div :class="{show: menuIsOpen}" class="collapse navbar-collapse navbar-collapse-overlay order-lg-3 " style="top: 3rem; bottom: 1rem" id="navbar-main-collapse">
          <div class="position-relative">
            <button class="navbar-toggler" v-on:click="menuIsOpen = false"  type="button" data-toggle="collapse" data-target="#navbar-main-collapse"
                    aria-controls="navbar-main-collapse" aria-expanded="false" aria-label="Toggle navigation">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <ul class="navbar-nav mr-3  overflow-auto">
            <li v-for="item in filterMenuItems(menu)" :key="item.route" class="nav-item nav-item-spaced d-lg-block text-nowrap">
              <router-link
                v-on:click="menuIsOpen = false"
                class="nav-link"
                :class="{active: isActive(item.route)}"
                :active-class="''"
                :exact-active-class="''"
                :to="{name: item.route}"
              >
                {{ item.title }}
              </router-link>

            </li>
          </ul>
          <ul class="navbar-nav align-items-lg-center d-lg-flex ml-lg-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ this.getUser ? this.getUser.username : '' }}
              </a>
              <ul class="dropdown-menu dropdown-nav-menu" aria-labelledby="navbarDropdownMenuLink">
                <li><a class="dropdown-item" href="#" @click.prevent="doLogout()">Log out</a></li>
              </ul>
            </li>
            <li class="nav-item nav-item-spaced d-lg-block">
              <div class="d-flex flex-column pr-2 text-nowrap">
                <small>{{ this.currentDate.format('HH:mm:ss') }} Local</small>
                <small>{{ this.currentDateUTC.format('HH:mm:ss') }} UTC</small>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </nav>
  </header>


</template>

<style scoped>

.nav-link.router-link-exact-active {
  background-color: rgba(255, 255, 255, .1);
  color: #fff;
}

.nav-link.active {
  background-color: #1f2d3e;
}

@media (max-width: 991.98px) {
  .nav-link.active {
    background-color: initial;
  }
}


</style>

<script>
import {mapGetters} from 'vuex';
import {useRoute} from "vue-router";
import moment from "moment";

export default {
  data() {
    return {
      menuIsOpen: false,
      currentDate: moment(),
      currentDateUTC: moment().utc(),
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ]),
    menu() {
      return [
        { route: 'dashboard', title: 'Dashboard' },
        { route: 'users', title: 'Users' },
        { route: 'deposits', title: 'Payments' },
        { route: 'giftLinks', title: 'Gift Links' },
        { route: 'tournaments', title: 'Tournaments' },
        { route: 'fraudUsers', title: 'Fraud Users' },
        { route: 'cashier', title: 'Cashier' },
        { route: 'mailings', title: 'Notifications' },
        /*{ route: 'emulator', title: 'Emulator' },
        { route: 'pushMessages', title: 'Push Messages' },*/
        { route: 'settings', title: 'Settings' },
      ]
    }
  },
  methods: {
    getDate: function () {
        this.currentDate = moment();
        this.currentDateUTC = moment().utc();
    },
    isActive: function (routeName) {
        const route = useRoute();
        return route.name === routeName || route.meta.group === routeName;
    },
    toggleMenu: function () {
      this.menuIsOpen = !this.menuIsOpen;
    },
    navItemClass: function (item) {
      if (item.type === 'item') {
        return '';
      }
      for (let index in item.items) {
        if (item.items[index].route === this.$route.name) {
          return 'menu-open';
        }
      }
      return '';
    },
    filterMenuItems: function (items) {
      return items.filter(item => this.hasAccess(item));
    },
    doLogout: function () {
      this.$store.dispatch('logout');
    },
    hasAccess(item) {
      return true;
    },
  },
  mounted() {
    setInterval(this.getDate, 1000);
  },
};
</script>
