import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Login from '@/views/Login.vue'
import Dashboard from "@/views/Dashboard.vue";
import GiftLinks from "@/views/GiftLinks.vue";
import Users from "@/views/Users.vue";
import FraudUsers from "@/views/FraudUsers.vue";
import UserProfile from "@/views/UserProfile.vue";
import Deposits from "@/views/Deposits.vue";
import Tournaments from "@/views/Tournaments.vue";
import PushMessages from "@/views/PushMessages.vue";
import Cashier from "@/views/Cashier.vue";
import Mailings from "@/views/Mailings.vue";
import TournamentPlayers from "@/views/TournamentPlayers.vue";
import Emulator from "@/views/Emulator.vue";
import Settings from "@/views/Settings.vue";
import MailingSegments from "@/views/MailingSegments.vue";

const routes: Array<RouteRecordRaw> = [
    {path: '/', name: 'dashboard', component: Dashboard, meta: {requiresAuth: true,},},
    {path: '/gift-links', name: 'giftLinks', component: GiftLinks, meta: {requiresAuth: true,},},
    {path: '/users', name: 'users', component: Users, meta: {requiresAuth: true,},},
    {path: '/fraud-users', name: 'fraudUsers', component: FraudUsers, meta: {requiresAuth: true,},},
    {path: '/deposits', name: 'deposits', component: Deposits, meta: {requiresAuth: true,},},
    {path: '/tournaments', name: 'tournaments', component: Tournaments, meta: {requiresAuth: true,},},
    {path: '/tournament/:tid/players', name: 'tournamentPlayers', component: TournamentPlayers, meta: {requiresAuth: true,},},
    {path: '/push-messages', name: 'pushMessages', component: PushMessages, meta: {requiresAuth: true,},},
    {path: '/cashier', name: 'cashier', component: Cashier, meta: {requiresAuth: true,},},
    {path: '/mailings', name: 'mailings', component: Mailings, meta: {requiresAuth: true, group: 'mailings'},},
    {path: '/mailings/segments', name: 'mailingsSegments', component: MailingSegments, meta: {requiresAuth: true, group: 'mailings'},},
    {path: '/users/:id', name: 'userProfile', component: UserProfile, meta: {requiresAuth: true,},},
    {path: '/emulator', name: 'emulator', component: Emulator, meta: {requiresAuth: true,},},
    {path: '/settings', name: 'settings', component: Settings, meta: {requiresAuth: true,},},
    {path: '/login', name: 'login', component: Login},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

