import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';
import AccessToken from "@/models/AccessToken";
import AdminUser from "@/models/AdminUser";
import router from './../router'
import slots from "@/store/slots";
import mailingTemplates from "@/store/mailingTemplates";
import mailingSegments from "@/store/mailingSegments";

function loadTokenFromLocalStorage(): AccessToken | null
{
  try {
    const token = localStorage.getItem('auth') || '';
    return JSON.parse(token) as AccessToken;
  } catch (e) {
    return null
  }
}

const auth = loadTokenFromLocalStorage();

export default createStore({
  state: {
    auth: auth as AccessToken | null,
    user: null as AdminUser | null,
  },
  mutations: {
    authenticate(state, token: AccessToken) {
      state.auth = token;
      localStorage.setItem('auth', JSON.stringify({token: token.token, refreshToken: token.refreshToken}));
    },
    removeAuth(state) {
      localStorage.removeItem('auth');
      state.auth = null;
      state.user = null;
      router.push({ name: 'login'})
    },
    setUser(state, user: AdminUser | null) {
      state.user = user;
    }
  },
  actions: {
    loadUser({commit}) {
      return axios({
        url: '/admin-users/me',
        method: 'GET',
      }).then((resp) => {
        commit('setUser', resp.data as AdminUser);
      }).catch(() => {

      });
    },
    logout({commit}) {
      commit('removeAuth');
    },
    login({commit}, credentials) {
      return axios({
        url: '/token/get',
        data: credentials,
        method: 'POST',
      }).then((resp) => {
        commit('authenticate', resp.data as AccessToken);
      }).catch((error) => {
        throw error;
      });
    }
  },
  getters: {
    isLoggedIn: state => state.auth !== null,
    getAuth: state => state.auth,
    getUser: state => state.user,
  },
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    slots, mailingTemplates, mailingSegments
  }
})
