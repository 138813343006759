class NumberFormatter {
    private intlFormatters = [] as Intl.NumberFormat[];

    public formatMoney(value: number|null, currency: string = 'USD'): string|null {
        if (value === null || isNaN(value)) {
            return null;
        }
        let formattedValue = this.getIntlFormatter(2).format(value).replace(/,/g, ' ');

        switch (currency) {
            case 'USD':
                return formattedValue + '$'
            default:
                return formattedValue;
        }
    }

    public formatNumber(value: number|null, minimumFractionDigits: number = 0): string|null {
        if (value === null || isNaN(value)) {
            return null;
        }
        return this.getIntlFormatter(minimumFractionDigits).format(value).replace(/,/g, ' ')
    }

    private getIntlFormatter(minimumFractionDigits: number): Intl.NumberFormat {
        if (!this.intlFormatters[minimumFractionDigits]) {
            this.intlFormatters[minimumFractionDigits] = new Intl.NumberFormat('en-US', {
                minimumFractionDigits,
                maximumFractionDigits: minimumFractionDigits
            });
        }
        return this.intlFormatters[minimumFractionDigits]
    }
}

const numberFormatter = new NumberFormatter();

export {
    numberFormatter
}
