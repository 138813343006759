import {ChartOptions} from "chart.js";
import {numberFormatter} from "@/service/NumberFormatter";
import {dateHelper} from "@/service/DateHelper";

export const depositChartsOptions: ChartOptions = {
    animation: {
        duration: 0
    },
    responsive: true,
    aspectRatio: 16/8,
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: function(context) {
                    return (context.dataset.label || '') +': ' + numberFormatter.formatMoney(context.raw as number);
                }
            }
        }
    },
    scales: {
        y: {
            ticks: {
                stepSize: 200,
                callback: function(value: number) {
                    return numberFormatter.formatMoney(value);
                }
            }
        },
        x: {
            ticks: {
                callback: function(value: number): string {
                    return dateHelper.format(this.getLabelForValue(value), 'DD-MM');
                }
            }
        }
    }
} as ChartOptions;