class EnumHelper {

  public enumToOptions(TYPE: any, lowerCase: boolean = true) {
    let options = {} as any;
    Object.values(TYPE).forEach((value: any) => {
      let name = Object.keys(TYPE)[Object.values(TYPE).indexOf(value)];
      if (!name) {
        return;
      }

      name = name.replace(/_/g, ' ');
      options[value] = lowerCase ? name.toLowerCase() : name;
    })
    return options;
  }
}

export const enumHelper = new EnumHelper();
