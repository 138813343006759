export enum TransactionType {
    SPIN = 1,
    WIN = 2,
    CLAIM_COIN_GIFT = 3,
    CLAIM_SPIN_GIFT = 4,
    SEND_COIN_GIFT = 5,
    SEND_SPIN_GIFT = 6,
    GAME_UNLOCKED = 7,
    LEVEL_UP = 8,
    CLAIM_BONUS = 9,
    FREESPIN = 10,
    CONVERT_SPIN_GIFTS = 11,
    MERGE_SPIN_GIFTS = 12,
    MAIL_OPENED = 13,
    DAILY_MAILER_PRIZE_REDEEMED = 14,
    REAL_MONEY_REGISTRATION = 15,
    MOBILE_LOGIN = 16,
    FACEBOOK_LOGIN = 17,
    REAL_MONEY_LOGIN = 18,
    GUEST_CONVERT = 19,
    PRELOADER_START = 20,
    PRELOADER_COMPLETE = 21,
    GAMELOADER_START = 22,
    GAMELOADER_COMPLETE = 23,
    SEND_VIRAL_PRIZE = 24,
    CLAIM_VIRAL_PRIZE = 25,
    MOBILE_ANDROID_LOGIN = 26,
    MOBILE_IOS_LOGIN = 27,
    DATA_LOAD_COMPLETE = 29,
    CLAIM_SUPERBONUS = 30,
    COMPED = 31,
    PURCHASE_FB_COIN_BUNDLE = 32,
    PURCHASE_FB_COINS = 33,
    PURCHASE_APPLE_COIN_BUNDLE = 34,
    PURCHASE_GOOGLE_COIN_BUNDLE = 35,
    PURCHASE_AMAZON_COIN_BUNDLE = 36,
    ADMIN_REMOVE_COINS = 37,
    MOBILE_KINDLE_LOGIN = 38,
    FREESPIN_WIN = 39,
    PURCHASE_HUAWEI_COIN_BUNDLE = 40,
    PURCHASE_OTHER_COIN_BUNDLE = 41,
    TOURNAMENT_WIN = 42,
    VIDEO_REWARD = 43,
    POKER_COMBINATION_WIN = 44,
    ADMIN_ADD_COINS = 45,
    APP_REVIEW_REWARD = 46,
    APP_UPDATE_REWARD = 47,
    MAP_TOURNAMENT_CHECKPOINT_PRIZE = 48,
    CASHBACK_REWARD = 49,
}