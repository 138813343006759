import Axios from "axios";
import {Module} from "vuex";
import MailingSegmentDTO from "@/models/MailingSegmentDTO";

const mailingSegments: Module<any, any> = {
    namespaced: true,
    state: {
        list: [] as MailingSegmentDTO[],
        loaded: false,
        promise: null,
    },
    actions: {
        async loadList ({ state, commit }) {
            let promise = Axios({
                url: '/segments/all',
                method: 'GET',
            }).then(resp => {
                commit('SET_LIST', resp.data as MailingSegmentDTO[]);
                commit('SET_LOADED', true);
            }).finally(() => {
                commit('SET_PROMISE', null);
            })

            commit('SET_PROMISE', promise);

            return promise;
        },
    },
    mutations: {
        SET_LIST: (state, payload: MailingSegmentDTO[]) => {
            state.list = payload;
        },
        SET_LOADED: (state, value) => {
            state.loaded = value;
        },
        SET_PROMISE: (state, payload) => {
            state.promise = payload;
        },
    },
    getters: {
        slot: (state) => (id: number): MailingSegmentDTO | null => {
            return state.list.find((item: MailingSegmentDTO) => item.id === id);
        },
        name: (state) => (id: number) => {
            let segment = state.list.find((item: MailingSegmentDTO) => item.id === id);
            return segment ? segment.name : id
        },
        list: (state): MailingSegmentDTO[] => state.list,
        mailingList: (state): MailingSegmentDTO[] => state.list.filter((item: MailingSegmentDTO) => item.type === 'mailing'),
        pushList: (state): MailingSegmentDTO[] => state.list.filter((item: MailingSegmentDTO) => item.type === 'push'),
        loaded: (state) => state.loaded,
    }
}

export default mailingSegments;
