import Axios from "axios";
import {Module} from "vuex";
import SlotDTO from "@/models/SlotDTO";

const slots: Module<any, any> = {
    namespaced: true,
    state: {
        list: [] as SlotDTO[],
        loaded: false,
        promise: null,
    },
    actions: {
        async loadList ({ state, commit }) {
            let promise = Axios({
                url: '/slots',
                method: 'GET',
            }).then(resp => {
                commit('SET_LIST', resp.data as SlotDTO[]);
                commit('SET_LOADED', true);
            }).finally(() => {
                commit('SET_PROMISE', null);
            })

            commit('SET_PROMISE', promise);

            return promise;
        },
    },
    mutations: {
        SET_LIST: (state, payload: SlotDTO[]) => {
            state.list = payload;
        },
        SET_LOADED: (state, value) => {
            state.loaded = value;
        },
        SET_PROMISE: (state, payload) => {
            state.promise = payload;
        },
    },
    getters: {
        slot: (state) => (id: number): SlotDTO|null => {
            return state.list.find((item:SlotDTO) => item.id === id);
        },
        name: (state) => (id: number) => {
            let slot = state.list.find((item:SlotDTO) => item.id === id);
            return slot ? slot.name : id
        },
        list: (state): SlotDTO[] => state.list,
        loaded: (state) => state.loaded,
    }
}

export default slots;
