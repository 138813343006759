import {ChartOptions} from "chart.js";
import {dateHelper} from "@/service/DateHelper";

export const depositsStatusChartOptions: ChartOptions = {
    animation: {
        duration: 0
    },
    responsive: true,
    aspectRatio: 16/8,
    plugins: {
        legend: {
            display: false
        },
    },
    scales: {
        x: {
            ticks: {
                callback: function(value: number): string {
                    return dateHelper.format(this.getLabelForValue(value), 'DD-MM');
                }
            },
            stacked: true
        },
        y: {
            stacked: true
        }
    }
} as ChartOptions;