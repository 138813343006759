import axios from "axios";
import qs from "qs";
import User from "@/models/User";

class UserRepository {
    private usersCache = {} as User[]

    public findUsers = async (ids: number[]): Promise<User[]> => {
        let response = await axios({
            url: '/users',
            method: 'GET',
            params: {
                ids: ids,
                limit: ids.length,
                offset: 0
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        let users = response.data.items as User[]
        users.forEach((user) => {
            this.usersCache[user.id] = user;
        })

        return users;
    }

    public findUser = (id: number): User|null => {
        return this.usersCache[id] ?? null
    }
}

export const userRepository = new UserRepository();