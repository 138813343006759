import Axios from "axios";
import {Module} from "vuex";
import MailingTemplateDTO from "@/models/MailingTemplateDTO";

const mailingTemplates: Module<any, any> = {
    namespaced: true,
    state: {
        list: [] as MailingTemplateDTO[],
        loaded: false,
        promise: null,
    },
    actions: {
        async loadList ({ state, commit }) {
            let promise = Axios({
                url: '/mailings/templates/all',
                method: 'GET',
            }).then(resp => {
                commit('SET_LIST', resp.data as MailingTemplateDTO[]);
                commit('SET_LOADED', true);
            }).finally(() => {
                commit('SET_PROMISE', null);
            })

            commit('SET_PROMISE', promise);

            return promise;
        },
    },
    mutations: {
        SET_LIST: (state, payload: MailingTemplateDTO[]) => {
            state.list = payload;
        },
        SET_LOADED: (state, value) => {
            state.loaded = value;
        },
        SET_PROMISE: (state, payload) => {
            state.promise = payload;
        },
    },
    getters: {
        get: (state) => (id: number): MailingTemplateDTO | null => {
            return state.list.find((item: MailingTemplateDTO) => item.id === id);
        },
        title: (state) => (id: number) => {
            let template = state.list.find((item: MailingTemplateDTO) => item.id === id);
            return template ? template.title : id
        },
        list: (state): MailingTemplateDTO[] => state.list,
        loaded: (state) => state.loaded,
    }
}

export default mailingTemplates;
