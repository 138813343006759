import {TransactionType} from "@/models/TransactionType";

class TransactionHelper {
  colors = [
    "#0178b2",
    "#f97e1b",
    "#6575ff",
    "#3bb22b",
    "#6b32af",
    "#57df8b",
    "#b20088",
    "#009238",
    "#ff8bf1",
    "#95d86e",
    "#3749ad",
    "#a59500",
    "#008cf2",
    "#de461a",
    "#019dab",
    "#ef125c",
    "#009562",
    "#ff72cb",
    "#00631e",
    "#b88eff",
    "#647c00",
    "#f29fff",
    "#515a00",
    "#733a8c",
    "#cdcb63",
    "#9f115a",
    "#73daa6",
    "#a80622",
    "#9cd58b",
    "#992351",
    "#006640",
    "#ff734d",
    "#89baff",
    "#7b6000",
    "#7b3a76",
    "#eebf60",
    "#b290c2",
    "#465917",
    "#ff8fb8",
    "#64501d",
    "#f5b3d7",
    "#debe63",
    "#922f4b",
    "#8f8800",
    "#ff7d95",
    "#dfaa85",
    "#8e4b5c",
    "#ffa388",
    "#883a35",
    "#ff938c"
  ]

  othersTypes = null as TransactionType[]|null;

  public getTransactionType(type: number): string {
    let name = Object.keys(TransactionType)[Object.values(TransactionType).indexOf(type)];
    if (!name) {
      return type.toString();
    }
    return name.replace(/_/g, ' ').toLowerCase();
  }

  public getTransactionColor(type: number): string {
    return this.colors[type - 1];
  }

  public getSlotsTypes(): TransactionType[]
  {
    return [TransactionType.SPIN, TransactionType.WIN, TransactionType.FREESPIN, TransactionType.FREESPIN_WIN];
  }

  public getTournamentsTypes(): TransactionType[]
  {
    return [TransactionType.TOURNAMENT_WIN, TransactionType.POKER_COMBINATION_WIN, TransactionType.MAP_TOURNAMENT_CHECKPOINT_PRIZE];
  }

  public getPaymentsTypes(): TransactionType[]
  {
    return [TransactionType.PURCHASE_OTHER_COIN_BUNDLE, TransactionType.PURCHASE_FB_COINS, TransactionType.PURCHASE_HUAWEI_COIN_BUNDLE, TransactionType.PURCHASE_AMAZON_COIN_BUNDLE, TransactionType.PURCHASE_APPLE_COIN_BUNDLE, TransactionType.PURCHASE_FB_COIN_BUNDLE, TransactionType.PURCHASE_GOOGLE_COIN_BUNDLE];
  }

  public getBonusesTypes(): TransactionType[]
  {
    return [TransactionType.CASHBACK_REWARD, TransactionType.LEVEL_UP, TransactionType.CLAIM_BONUS, TransactionType.CLAIM_COIN_GIFT, TransactionType.CLAIM_VIRAL_PRIZE, TransactionType.CLAIM_SPIN_GIFT, TransactionType.CLAIM_SUPERBONUS, TransactionType.VIDEO_REWARD, TransactionType.APP_REVIEW_REWARD, TransactionType.APP_UPDATE_REWARD];
  }

  public getOtherTypes(): TransactionType[]
  {
    if (this.othersTypes === null) {
      const transactionsOptions = Object.values(TransactionType).filter(el => typeof el === 'number') as TransactionType[];

      this.othersTypes = transactionsOptions.filter((el) => {
        return !(this.getTournamentsTypes()).includes(el)
            && !(this.getPaymentsTypes()).includes(el)
            && !(this.getSlotsTypes()).includes(el)
            && !(this.getBonusesTypes()).includes(el)
      })
    }
    return this.othersTypes;
  }
}

export const transactionHelper = new TransactionHelper();
