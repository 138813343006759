import moment from 'moment';

class DateHelper {
  public format(date: string | null, format: string | null = null, defaultValue: string = '') : string {
    if (date === null) {
      return defaultValue;
    }

    return (moment(date)).utcOffset(0).format(format ?? 'YYYY-MM-DD HH:mm');
  }
}

export const dateHelper = new DateHelper();
