import {enumHelper} from "@/service/EnumHelper"
import {TournamentBankrollType} from "@/models/TournamentBankrollType";
import {TournamentWinnersType} from "@/models/TournamentWinnersType";
import {TournamentPreviewImage} from "@/models/TournamentPreviewImage";
import {PointsCalculationType} from "@/models/PointsCalculationType";
import {TournamentJoinType} from "@/models/TournamentJoinType";

export const tournamentBankrollTypeOptions = enumHelper.enumToOptions(TournamentBankrollType)
export const tournamentWinnersTypeOptions = enumHelper.enumToOptions(TournamentWinnersType)
export const tournamentImageOptions = enumHelper.enumToOptions(TournamentPreviewImage, false)
export const tournamentPointsTypeOptions = enumHelper.enumToOptions(PointsCalculationType)
export const tournamentJoinTypeOptions = enumHelper.enumToOptions(TournamentJoinType)